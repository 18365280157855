import isBot from "isbot";
import { nanoid } from "nanoid";

const COOKIE_TRACKER_KEY = 't';
const COOKIE_SESSION_KEY = 's';

import Tracker from "./tracker";
import TrackerHelper from "../helper";

const randomID = () => {
  return nanoid(10);
  return Math.random().toString(36).substr(2, 9);
}

class TrackerClient {

  #isSimulation;
  #isTracking;
  #tracker;
  #session;

  constructor() {
    this.#isTracking = true;
    this.#isSimulation = false;
    this.#tracker = new Tracker(COOKIE_TRACKER_KEY, COOKIE_TRACKER_KEY);
    this.#session = new Tracker(COOKIE_SESSION_KEY, COOKIE_SESSION_KEY, randomID());
  }

  shouldTrack() {
    if (!navigator) {
      console.log('[Skip Tracking] !navigator');
      return false;
    }
    if (isBot(navigator.userAgent)) {
      console.log('[Skip Tracking] isBot');
      return false;
    }
    if (this.#isTracking !== true) {
      console.log('[Skip Tracking] isTracking !== true');
      return false;
    }
    console.log('[Tracking]');
    return true;
  }

  dispatchEvent(params) {
    navigator.sendBeacon(
      // "/api/v1/log",
      "https://hhmrmrg13l.execute-api.ap-southeast-1.amazonaws.com/prod/jwt/log",
      JSON.stringify(params),
    );
  }

  // Public API
  enableTracking() {
    this.#isTracking = true;
  }

  disableTracking() {
    this.#isTracking = false;
  }

  track(c, a, extra) {
    if (!this.shouldTrack()) {
      return;
    }
    const params = {
      url: window.location.href,
      [COOKIE_TRACKER_KEY]: this.#tracker.get(),
      [COOKIE_SESSION_KEY]: this.#session.get(),
      c,
      a,
      ...extra,
    }
    if (this.#isSimulation) {
      console.log('[Track Event]');
      console.log(params);
    }
    else {
      this.dispatchEvent(params);
    }
  }

  convert(c, a, extra) {
    if (!this.shouldTrack()) {
      return;
    }
    this.track(c, a, extra);
    const t = TrackerHelper.updateTracker(this.#tracker.get(), this.#session.get());
    this.#tracker.set(t);
  }

  getTracker() {
    return this.#tracker.get();
  }

  getSession() {
    return this.#session.get();
  }

}

export default TrackerClient;
