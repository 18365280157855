import RocksolidTracker from "./rocksolid_tracker/v2/client.js";

if (window) {
  window.RocksolidTracker = new RocksolidTracker();
}

document.addEventListener("DOMContentLoaded", function(e) {
  const elements = document.querySelectorAll(".rocksolid-tracker");
  for (const el of elements) {
    el.addEventListener('click', function() {
      const { trackerCategory, trackerAction } = this.dataset;
      if (window.BlackholeTracker && trackerCategory) {
        window.BlackholeTracker.track(trackerCategory, trackerAction);
      }
    });
  }
});
