class TrackerHelper {

  isAdTracker(t) {
    if (typeof t !== "string") {
      return false;
    }
    if (t.length < 2) { // At least 2 characters long
      return false;
    }
    if (t.length > 8) { // Not more than 8 characters long
      return false;
    }
    var first = t[0].toLowerCase();
    var second = t[1].toLowerCase();
    var SIGNATURE = ["f", "g"];
    return SIGNATURE.includes(first) || SIGNATURE.includes(second);
  }

  updateTracker(t, s) {
    if (typeof t !== "string") { // Invalid tracker. Start tracking based on session.
      return s;
    }
    if (t) {
      if (this.isAdTracker(t)) { // Paid traffic. Start tracking based on session.
        return s;
      }
      else if (t.match(/^[0-9 ()+-]{5,}$/)) { // Old implementation with phone number. Start tracking based on session.
        return s;
      }
      else {
        return t;
      }
    }
    else { // Blank string. Start tracking based on session.
      return s;
    }
  }

}

export default new TrackerHelper();
