import Cookies from "universal-cookie";
const cookies = new Cookies();

class Tracker {

  #query_key;
  #cookie_key;

  constructor(query_key, cookie_key, def) {
    this.#query_key = query_key;
    this.#cookie_key = cookie_key;
    this.init(def);
  }

  getQueryParam(query_key) {
    // urlParams.get returns null if query param not found, empty string is valid
    const query_params = new URLSearchParams(window.location.search);
    return query_params.get(query_key);
  }

  getCookie(cookie_key) {
    return cookies.get(cookie_key);
  }

  setCookie(cookie_key, cookie_value) {
    if (cookie_value) {
      cookies.set(cookie_key, cookie_value, { path: '/', expires: new Date(new Date().setFullYear(new Date().getFullYear()+1)) });
    }
    else {
      cookies.remove(cookie_key, { path: '/' });
    }
  }

  init(def) {
    const value = this.getQueryParam(this.#query_key) !== null ? this.getQueryParam(this.#query_key) : this.get();
    this.set(value || def);
  }

  set(value) {
    this.setCookie(this.#cookie_key, value);
  }

  get() {
    return this.getCookie(this.#cookie_key);
  }

}

export default Tracker;
